import { createSlice } from "@reduxjs/toolkit";

import { themes, themeDefaultFront } from "../utils/themes";

const initialState = {
  loading: false,
  token: null,
  account: null,
  user: null,
  student: null,
  theme: themeDefaultFront,
  loginConfig: null,
};

export const accountReducer = createSlice({
  name: "accountconfig",
  initialState,
  reducers: {
    authRequest: (state) => {
      state.loading = true;
    },
    authSuccess: (state, action) => {
      const { token, account, user, student, loginConfig } =
        action.payload.data;
      return (state = {
        ...state,
        token,
        account,
        user,
        student,
        loginConfig,
        loading: false,
        theme: themes[user.theme],
      });
    },
    authError: (state) => {
      state.loading = false;
    },
    logoutUser: (state) => {
      state.loading = initialState.loading;
      state.token = initialState.token;
      state.account = initialState.account;
      state.user = initialState.user;
      state.theme = initialState.theme;
      state.student = initialState.student;
      state.loginConfig = initialState.loginConfig;
    },

    resetPasswordRequest: (state) => {
      state.loading = true;
    },
    resetPasswordSuccess: (state, action) => {
      const { user } = action.payload.data;
      return (state = {
        ...state,
        user,
        loading: false,
      });
    },
    resetPasswordError: (state) => {
      state.loading = false;
    },

    createAccountRequest: (state) => {
      state.loading = true;
    },
    createAccountSuccess: (state, action) => {
      state.loading = false;
      state.account = action.payload.data;
    },
    createAccountError: (state) => {
      state.loading = false;
    },

    createUpdateUserRequest: (state) => {
      state.loading = true;
    },
    createUpdateUserSuccess: (state, action) => {
      const user = action.payload.data;
      return (state = {
        ...state,
        user,
        loading: false,
        theme: themes[user.theme],
      });
    },
    createUpdateUserError: (state) => {
      state.loading = false;
    },
    getConfigLoginAccountRequest: (state) => {
      state.loading = true;
    },
    getConfigLoginAccountSuccess: (state, action) => {
      state.loginConfig = action.payload.data;
      state.account = { ...state.account, ...action.payload.data };
      state.loading = false;
      state.theme = state.user ? themes[state.user.theme] : themes.white;
    },
    getConfigLoginAccountError: (state) => {
      state.loading = false;
    },

    updateUserStudent: (state, action) => {
      const student = action.payload.data;

      return (state = {
        ...state,
        student,
      });
    },
  },
});

export const {
  authRequest,
  authSuccess,
  authError,
  logoutUser,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordError,
  createAccountRequest,
  createAccountSuccess,
  createAccountError,

  createUpdateUserRequest,
  createUpdateUserSuccess,
  createUpdateUserError,

  getConfigLoginAccountRequest,
  getConfigLoginAccountSuccess,
  getConfigLoginAccountError,

  updateUserStudent,
} = accountReducer.actions;

export default accountReducer.reducer;
