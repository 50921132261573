import {
  createAccountRequest,
  createAccountSuccess,
  createAccountError,
  createUpdateUserRequest,
  createUpdateUserSuccess,
  createUpdateUserError,
  getConfigLoginAccountRequest,
  getConfigLoginAccountSuccess,
  getConfigLoginAccountError,
} from "../reducers/accountReducer";

import { baseUrl, postFetch, getFetch } from "../utils/request";

export function createAccount(payload, callback) {
  return async (dispatch) => {
    dispatch(createAccountRequest());

    try {
      await fetch(`${baseUrl}/create-account`, postFetch(payload))
        .then((res) => res.json())
        .then((result) => {
          if (result.success) dispatch(createAccountSuccess(result));
          return callback(result, payload);
        });
    } catch (error) {
      dispatch(createAccountError(error));
      callback({ success: false, message: "Erro na API" });
    }
  };
}

export function createAccountAnonymous(payload, callback) {
  return async (dispatch) => {
    dispatch(createAccountRequest());

    try {
      await fetch(`${baseUrl}/create-account-anonymous`, postFetch(payload))
        .then((res) => res.json())
        .then((result) => {
          if (result.success) dispatch(createAccountSuccess(result));
          return callback(result, payload);
        });
    } catch (error) {
      dispatch(createAccountError(error));
      callback({ success: false, message: "Erro na API" });
    }
  };
}

export function updateAccount(payload, callback) {
  return async (dispatch) => {
    dispatch(createAccountRequest());

    try {
      await fetch(`${baseUrl}/update-account`, postFetch(payload))
        .then((res) => res.json())
        .then((result) => {
          if (result.success) dispatch(createAccountSuccess(result));
          return callback(result, payload);
        });
    } catch (error) {
      dispatch(createAccountError(error));
      callback({ success: false, message: "Erro na API" });
    }
  };
}

export function createUpdateUser(payload, callback) {
  return async (dispatch) => {
    dispatch(createUpdateUserRequest());

    try {
      await fetch(`${baseUrl}/user`, postFetch(payload))
        .then((res) => res.json())
        .then((result) => {
          if (result.success) dispatch(createUpdateUserSuccess(result));
          return callback && callback(result);
        });
    } catch (error) {
      dispatch(createUpdateUserError(error));
      callback({ success: false, message: "Erro na API" });
    }
  };
}

export function getConfigLoginAccount(token, callback) {
  return async (dispatch) => {
    dispatch(getConfigLoginAccountRequest());

    try {
      await fetch(`${baseUrl}/get-config-login-account/${token}`, getFetch())
        .then((res) => res.json())
        .then((result) => {
          if (result.success) dispatch(getConfigLoginAccountSuccess(result));
          return callback && callback(result);
        });
    } catch (error) {
      dispatch(getConfigLoginAccountError(error));
      callback({ success: false, message: "Erro na API" });
    }
  };
}
